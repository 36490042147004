/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // add header background on scroll
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 100) {
                $('body').addClass('scrolled');
            } else {
                $('body').removeClass('scrolled');
            }
        });

        $(function() {
          $('.height-match').matchHeight();
        });

        $(function() {
          $('.product-card-lower').matchHeight({
            byRow: false
          });
        });

        $(function() {
          $('.product-card-lower h3').matchHeight();
        });

        $(function() {
          $('.woo-summary-inner, .product-image-inner').matchHeight();
        });

        $(function() {
          $('.card-text h3').matchHeight();
        });

        $(function() {
          $('.card-text').matchHeight();
        });

        $(function() {
          $('.product-location').matchHeight({
            byRow: false
          });
        });

        $(function() {
          $('.product-date').matchHeight();
        });


        $(function() {
          $('.single-post-feed h3').matchHeight({
            byRow: false
          });
        });

        $(function() {
          $('.teacher-profile .teacher-name').matchHeight({
            byRow: false
          });
        });

        /*$(function() {
          $('.teacher-profile .teacher-description').matchHeight({
            byRow: true
          });
        });*/

        $(function() {
          $('.teacher-profile').matchHeight({
            byRow: true
          });
        });

        $(".enroll-btn").click(function() {
          $([document.documentElement, document.body]).animate({
              scrollTop: $("#pricing-options").offset().top - 100
          }, 1000);
        });

        $(".learn-btn").click(function() {
          $([document.documentElement, document.body]).animate({
              scrollTop: $("#book-overview").offset().top - 100
          }, 1000);
        });

          $(document).ready(function() {
            if($('.flexslider')[0]){
              $('.flexslider').flexslider({
                selector: ".slides > .slide",
                animation: "slide",
                controlNav: false,
                directionNav: false,
                slideshowSpeed: 9000,
                start: function(){
                     $('.testimonials .row').addClass('show-flexslider');
                },
              });
            }
          });

          $('.variations .value select').change(function() {
              setTimeout(function() {
                $('.price-block .woocommerce-Price-amount').remove();
                $('.price .woocs_price_code').hide();
                jQuery('.woocommerce-variation-price .woocommerce-Price-amount').clone().appendTo(jQuery('.price-block .price'));
              }, 50);
          });

          if ($("body").hasClass("single-product")) {

          }

          $(document).ready(function(){
            $('.mobile-nav-btn').click(function(){
              $('body').toggleClass('mobile-nav-open');
            });

            $('li.menu-item-has-children').click(function(){
              console.log('click');
              $(this).find('ul.sub-menu').slideToggle();
            });

          });

          if( $('.products-container').length ){
            var containerEl = document.querySelector('.products-container');
            var mixer = mixitup(containerEl);
    
            $(containerEl).on('mixEnd', function() {
              console.log('.products-container mixed');
            });
          }

          if( $('.teachers-list').length ){
            var containerEl = document.querySelector('.teachers-list');
            var mixer = mixitup(containerEl);
    
            $(containerEl).on('mixEnd', function() {
              console.log('.teachers-list mixed');
              
              // update block heights
              jQuery('.teacher-profile:visible').matchHeight({ remove: true });
              jQuery('.teacher-profile:visible').matchHeight({
                byRow: true
              });
            });
          }

          if(window.location.href.indexOf("/news/page/") > -1) {
            $([document.documentElement, document.body]).animate({
              scrollTop: $("#all-posts").offset().top - 60
            }, 500);
          }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(function() {
          $('.soundcloud,.new-book').matchHeight();
        });

        // $(function() {
        //   $('.about-michaela .left-col,.about-michaela .right-col').matchHeight();
        // });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'workshops': {
      init: function() {
        //JavaScript to be fired on the workshops page
        // var containerEl = document.querySelector('.products-container');
        // var mixer = mixitup(containerEl);

        // $(containerEl).on('mixEnd', function() {
        //   console.log('mixed');
        // });
      }
    },
    'store': {
      init: function() {
        // JavaScript to be fired on the workshops page
        var containerEl = document.querySelector('.products-container');
        var mixer = mixitup(containerEl);

        $(containerEl).on('mixEnd', function() {
          console.log('mixed');
        });

      }
    },
    'checkout': {
      init: function() {
        $(document).ready(function(){
          document.getElementById("mailchimp_woocommerce_newsletter").checked = true;
        });

      }
    },
    'news': {
      init: function() {
        // JavaScript to be fired on the workshops page
        $(function() {
          $('.blog-intro .podcast img, .blog-intro .intro-image').matchHeight();
        });

        $(function() {
          $('.featured-post-image, .author-card').matchHeight();
        });

        $(function() {
          $('.post-card-inner').matchHeight();
        });

        $(function() {
          $('.post-card-inner h3').matchHeight();
        });

        $(".view-all-posts").click(function() {
          $([document.documentElement, document.body]).animate({
              scrollTop: $("#all-posts").offset().top
          }, 1000);
        });


      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page
        $(function() {
          $('.contact-card p').matchHeight();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
